import { atom } from "recoil";
import { saveDataToLocal, getDataFromLocal } from "../../helpers/localStorage";

const localStorageEffect = (key) => ({ setSelf, onSet }) => {
  const savedValue = getDataFromLocal(key);
  if (savedValue != null
    && savedValue != 'undefined'
    && typeof savedValue != 'undefiend'
    && savedValue !== undefined) {
    setSelf(JSON.parse(savedValue));
  }
  onSet((newValue) => {
    saveDataToLocal(key, JSON.stringify(newValue));
  });
};

//------------------------------------------------------------------
//MARK: Create all the atoms here
//------------------------------------------------------------------
export const sidebarEnabled = atom({
  key: "sidebarEnabled",
  default: false,
  // effects_UNSTABLE: [localStorageEffect("sidebar")],
});

export const sidebarShowing = atom({
  key: "sidebarShowing",
  default: true,
  effects_UNSTABLE: [localStorageEffect("sidebarShowing")],
});

export const customer = atom({
  key: "customer",
  default: undefined,
  effects_UNSTABLE: [localStorageEffect("customer")],
});

export const trackingForLoggedUser = atom({
  key: "trackingForLoggedUser",
  default: undefined,
  effects_UNSTABLE: [localStorageEffect("trackingForLoggedUser")],
});

export const loginProcessCompleted = atom({
  key: "loginProcessCompleted",
  default: false,
  effects_UNSTABLE: [localStorageEffect("loginProcessCompleted")],
});

export const customerFileUploaded = atom({
  key: 'customerFileUploaded',
  default: undefined
})

export const navBarCustomers = atom({
  key: "navBarCustomers",
  default: undefined,
  effects_UNSTABLE: [localStorageEffect("navBarCustomers")],
});

export const authenticationServiceCustomers = atom({
  key: "authenticationServiceCustomers",
  default: undefined,
  effects_UNSTABLE: [localStorageEffect("authenticationServiceCustomers")],
});

export const cart = atom({
  key: "cart",
  default: [],
  effects_UNSTABLE: [localStorageEffect("cart")],
});

export const cartTotal = atom({
  key: "cartTotal",
  default: 0,
  effects_UNSTABLE: [localStorageEffect("cartTotal")],
});

export const order = atom({
  key: "order",
  default: {}, 
  effects_UNSTABLE: [localStorageEffect("order")],
});

export const products = atom({
  key: "products",
  default: [],
});

/*
export const userIsStillLoggingIn = atom({
  key: "userIsStillLoggingIn",
  default: true,
});
*/

export const mixedProducts = atom({   // To always have available all the localized descriptions of the products
  key: "mixedProducts",
  default: [],
  effects_UNSTABLE: [localStorageEffect("mixedProducts")],
});

export const filteredProductsForHolding = atom({
  key: "filteredProductsForHolding",
  default: [],
  effects_UNSTABLE: [localStorageEffect("filteredProductsForHolding")],
});

export const assegnaInvitiData = atom({
  key: "assegnaInvitiData",
  default: [],
});

export const checkBannerStatus = atom({
  key: "checkBannerStatus",
  default: false,
});

export const bannerData = atom({
  key: "bannerData",
  default: null,
});

export const assegnaInvitiForm = atom({
  key: "assegnaInvitiForm",
  default: {},
});

export const canSeeProfiloPage = atom({
  key: "canSeeProfiloPage",
  default: true,
  effects_UNSTABLE: [localStorageEffect("canSeeProfiloPage")],
});

export const language = atom({
  key: "language",
  default: "it",
  effects_UNSTABLE: [localStorageEffect("language")],
});

export const swipedFinger = atom({
  key: "swipedFinger",
  default: null
})

export const warningAnomaliesCount = atom({
  key: "warningAnomaliesCount",
  default: {
    unread: 0,
    atLeastOne: false,
  },
});

export const invoiceAvailable = atom({
  key: "invoiceAvailable",
  default: null,
  effects_UNSTABLE: [localStorageEffect("invoiceAvailable")],
});

export const mustUpdateAnomalyCounts = atom({
  key: "mustUpdateAnomalyCounts",
  default: 0,
});
