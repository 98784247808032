import * as React from "react";

function SvgInforounded(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.667 8A7.312 7.312 0 018 .667 7.312 7.312 0 0115.333 8 7.312 7.312 0 018 15.333 7.312 7.312 0 01.667 8zM2 8c0 3.333 2.667 6 6 6s6-2.667 6-6-2.667-6-6-6-6 2.667-6 6zm6.667 0v2.667c0 .4-.267.666-.667.666s-.667-.266-.667-.666V8c0-.4.267-.667.667-.667s.667.267.667.667zm0-2.667c0-.2-.067-.333-.2-.466-.134-.134-.4-.2-.6-.2-.039.038-.055.054-.074.061-.015.005-.031.005-.06.005-.033 0-.05.017-.066.034-.017.016-.034.033-.067.033l-.067.067a.605.605 0 00-.2.466c0 .2.067.334.2.467 0 0 0 .067.067.067.033 0 .05.016.067.033.016.017.033.033.066.033.039.039.055.055.075.062.014.005.03.005.059.005H8c.2 0 .333-.067.467-.2a.605.605 0 00.2-.467z"
        fill={props.fill ?? "#212529"}
      />
    </svg>
  );
}

export default SvgInforounded;
