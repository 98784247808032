import { IS_NUMBER_REGEX } from "../../constants/regex";

const TextInput = ({
  label = null,
  onChange = () => {},
  onBlur = () => {},
  id = null,
  css = "",
  placeholder,
  icon,
  compCss,
  field = null,
  error = false,
  multipleSelectIndex = false,
  onKeyUp = null,
  maxLength = null,
  inputType="text",
  min="1",
  max="10000000000",
  labelFontSize=false,
  disabled,
  pageTranslationCode = null,
  labelBottomMargin = "",
  ...rest
}) => {

  const onChangeHandler = (e) => {
    if (field === "num") {
      if (e.target.value === "") {
        onChange(0, multipleSelectIndex);
      } else if (!IS_NUMBER_REGEX.test(e.target.value) || isNaN(e.target.value)) {
        onChange(1, multipleSelectIndex);
      } else {
        const number = Number(e.target.value);
        onChange(number, multipleSelectIndex);
      }
    } else {
      onChange(e.target.value, multipleSelectIndex);
    }
  };

  let labelFontSizeToUse = 'font-normal'
  if(labelFontSize) {
    labelFontSizeToUse = labelFontSize
  }

  return (
    <div className={`flex flex-col w-full ${compCss} relative`}>
      <div 
        className='flex justify-between w-full'
      >
        {label && (
          <span
            className={` ${labelFontSizeToUse} mb-2 ${
              error ? "text-app-redLight" : "text-app-mediumgrey"
            }`}
          >
            {label}
          </span>
        )}
      </div>
      <input
        type={inputType}
        id={id}
        min={min}
        max={max}
        disabled={disabled}
        style={disabled ? { color: "rgb(108 117 125)" } : {}}
        className={`${css} w-full rounded-md border px-4 py-3 transition-colors  focus:outline-none disabled:cursor-not-allowed ${
          error
            ? "border-app-redLight focus:border-app-redLight"
            : "border-app-line focus:border-app-orange"
        }`}
        onChange={onChangeHandler}
        placeholder={placeholder ?? label}
        {...rest}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        onBlur={onBlur}
      />
      {icon && icon}
      {error && (
        <span
          className={`absolute top-20 ${labelBottomMargin} text-app-redLight text-xs`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default TextInput;
