import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import classNames from "classnames";
//Recoil
import { useAtoms } from "../../recoil/hooks";
import useTranslation from "next-translate/useTranslation";
import { useSession } from "next-auth/react";
import VideoTutorialModal from "../../components/modal/VideoTutorial"
import { getDataFromLocal, removeDataFromLocal } from "../../helpers/localStorage";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';


const Sidebar = ({ show = false }) => {
  const { state } = useAtoms();
  const { asPath, push } = useRouter();

  const { t, lang } = useTranslation();
  const { data: session, status } = useSession();
  const [showVideoTutorialModal, setShowVideoTutorialModal] = useState(false)
  const [hasMounted, setHasMounted] = useState(false);
  const [showAnomaliesBadge, setShowAnomaliesBadge] = useState(true);

  useEffect(() => {
    setHasMounted(true);

    const pageFrom = getDataFromLocal("page-from")
    if(pageFrom 
      && pageFrom == "confirm-data-page"
      && process.env.VIDEO_TUTORIALS) {
      removeDataFromLocal("page-from")
      setShowVideoTutorialModal(true)
    }
  }, [])

  const toggleVideoTutorialModal = () => {
    setShowVideoTutorialModal(!showVideoTutorialModal)
  }

  const closeVideoTutorialModal = () => {
    setShowVideoTutorialModal(false)
  }

  const pageTranslationCode = `common${
    process.env.LOCALES_SUFFIX
  }`;

  let langToUseAsUrlPrefix = "";
  if (lang !== "it") {
    langToUseAsUrlPrefix = "en/";
  }

  const customerMenuItems = ((customer) => {
    if (customer?.customer_type) {
      const customerMenuItems = JSON.parse(
        process.env.MENU_VISIBILITY_PER_ROLE
      );
      return customerMenuItems[customer.customer_type];
    }
    return {};
  })(state?.customer);
  
  const itemClass =
    "font-normal leading-normal text-sm box-border no-underline w-full relative inline-block pt-2 pb-2 pl-5 pr-5 hover:bg-white hover:text-gray-800 border-0 hover:border-l-4 border-app-orange";
  
  return (
    <>
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <>
        <nav className="sidebar font-normal leading-normal text-left text-sm box-border w-64 top-0 left-0 min-h-screen h-full bg-app-darkbg text-white overflow-auto  font-tk-usual block">
          <div className="sidebar-header" />
          <ul key="list-menu-sidebar" className="list-none">
            {hasMounted && (
              <li className="home-test">
                <div>
                  <a
                    className={classNames(
                      asPath === "/"
                        ? "bg-white text-gray-800 border-l-4"
                        : "",
                      itemClass
                    )}
                    key="link-home"
                    href={"/" + langToUseAsUrlPrefix}
                  >
                    {t(`${pageTranslationCode}:sidebar.home`)}
                  </a>
                </div>
              </li>
            )}

            {hasMounted && customerMenuItems?.invite && (
              <li className="invite-test">
                  <div>
                    <a
                      className={classNames(
                        asPath === "/main_management" || asPath === "/buy/g"
                          ? "bg-white text-gray-800 border-l-4"
                          : "",
                        itemClass
                      )}
                      key="link-invite"
                      href={"/" + langToUseAsUrlPrefix + "main_management"}
                    >
                      {t(`${pageTranslationCode}:sidebar.g`)}
                    </a>
                  </div>
              </li>
            )}
            
            {hasMounted && customerMenuItems?.operational && (
              <li className="operational-test">
                  <div>
                    <a
                      className={classNames(
                        asPath === "/op_management" || asPath === "/buy/o"
                          ? "bg-white text-gray-800 border-l-4"
                          : "",
                        itemClass
                      )}
                      key="link-operational"
                      href={"/" + langToUseAsUrlPrefix + "op_management"}
                    >
                      {
                        process.env.IS_DIREZIONE ?
                          t(`${pageTranslationCode}:sidebar.o_direzione`) :
                          t(`${pageTranslationCode}:sidebar.o`)
                      }
                    </a>
                  </div>
              </li>
            )}

            <li key="menu-purchase">
              <a id="link-purchase"
                className={classNames(
                  asPath === "/purchase"
                    ? "bg-white text-gray-800 border-l-4"
                    : "",
                  itemClass
                )}
                href={"/" + langToUseAsUrlPrefix + "purchase"}
              >
                {t(`${pageTranslationCode}:sidebar.purchase`)}
              </a>
            </li>
            
            {hasMounted && state.invoiceAvailable && (
              <li key="menu-profile">
                <a
                  key="link-profile"
                  className={classNames(
                    asPath === "/profile"
                      ? "bg-white text-gray-800 border-l-4"
                      : "",
                    itemClass
                  )}
                  href="/profile"
                >
                  {t(`${pageTranslationCode}:sidebar.prof`)}
                </a>
              </li>
            )}

            <li 
              className="flex flex-row items-center relative"
              key="email-anomalies"
            >
                  <a
                    key="link-email-anomalies"
                    className={classNames(
                      asPath === "/email_anomalies"
                        ? "block bg-white text-gray-800 border-l-4"
                        : "block",
                      itemClass
                    )}
                    href="/email_anomalies"
                  >
                  {
                    state.warningAnomaliesCount.atLeastOne > 0 ? (
                      <Badge 
                        badgeContent={state.warningAnomaliesCount.unread > 0 ? state.warningAnomaliesCount.unread : 1}
                          color="warning"
                          showZero={false}
                          max={999}
                          variant={state.warningAnomaliesCount.unread > 0 ? "standard" : "dot"}
                          sx={{
                          "& .MuiBadge-badge": {
                            top: 3,
                          },
                          }}
                        >
                        <div className="pr-4">
                          {t(`${pageTranslationCode}:sidebar.email_anomalies`)}
                        </div>
                      </Badge>
                    ) : (<div className="pr-4">
                      {t(`${pageTranslationCode}:sidebar.email_anomalies`)}
                    </div>)
                  }
                  </a>
            </li>
            
            {hasMounted && process.env.VIDEO_TUTORIALS && (
              <li key="video-tutorial">
                  <a
                    key="link-video-tutorial"
                    className={itemClass + " cursor-pointer"}
                    onClick={() => { toggleVideoTutorialModal() }}
                  >
                    {t(`${pageTranslationCode}:sidebar.video_tutorial`)}
                  </a>
              </li>
            )}

            <li key="logout" className="cursor-pointer">
              <span
                key="link-logout"
                className={itemClass}
                onClick={() => {
                  push("/logout");
                }}
                style={{ color: "red" }}
              >
                {t(`${pageTranslationCode}:sidebar.logout`)}
              </span>
            </li>
          </ul>
        </nav>

        <VideoTutorialModal showModalFromParent={showVideoTutorialModal}
          closeVideoTutorialModal={closeVideoTutorialModal}/>
        </>
      </Transition>
    </>
  );
};

export default Sidebar;
