import * as React from "react";

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.733 11.267c-.066 0-.133-.067-.2-.134L4.867 8.467a.644.644 0 010-.934.644.644 0 01.933 0l1.533 1.534V1.333c0-.4.267-.666.667-.666s.667.266.667.666v7.734L10.2 7.533a.645.645 0 01.933 0 .645.645 0 010 .934l-2.666 2.666c-.034.034-.067.05-.1.067-.034.017-.067.033-.1.067-.067.066-.2.066-.267.066s-.2 0-.267-.066zm6.934 2.066v-2c0-.4-.267-.666-.667-.666s-.667.266-.667.666v2c0 .4-.266.667-.666.667H3.333c-.4 0-.666-.267-.666-.667v-2c0-.4-.267-.666-.667-.666s-.667.266-.667.666v2c0 1.134.867 2 2 2h9.334c1.133 0 2-.866 2-2z"
        fill={props.fill || "#000"}
      />
    </svg>
  );
}

export default SvgDownload;
