import * as React from "react";

function SvgSn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="Livello_1" 
      data-name="Livello 1" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 227.14 49.84" 
      width="1em"
      height="1em"
      {...props}
    >
      <g>
        <path className="SN_svg" d="M183.98,28.77c-0.25-0.6-0.55-1.18-0.89-1.74c-1.32-2.19-3.44-4.03-6.34-5.51c-2.91-1.48-7.72-2.94-14.43-4.39
		c-2.71-0.56-4.43-1.17-5.15-1.83c-0.75-0.63-1.12-1.34-1.12-2.14c0-1.09,0.45-2.01,1.36-2.76c0.9-0.76,2.25-1.14,4.04-1.14
		c2.17,0,3.87,0.51,5.1,1.53c1.23,1.02,2.04,2.65,2.43,4.88l14.48-0.85c-0.63-5.15-2.62-8.91-5.95-11.28
		C174.16,1.18,169.32,0,162.97,0c-5.18,0-9.25,0.65-12.23,1.95c-2.97,1.3-5.2,3.09-6.68,5.36c-1.48,2.27-2.22,4.69-2.22,7.24
		c0,3.89,1.45,7.09,4.34,9.6c2.87,2.51,7.68,4.52,14.41,6.04c4.12,0.9,6.74,1.86,7.87,2.88c1.13,1.02,1.7,2.17,1.7,3.46
		c0,1.36-0.59,2.55-1.78,3.58c-1.19,1.03-2.88,1.54-5.07,1.54c-2.94,0-5.2-1.01-6.78-3.02c-0.97-1.24-1.62-3.05-1.93-5.43L141.84,34
		v7.34c0.91,1.92,2.12,3.66,3.66,5.19c1.27,1.28,2.96,2.3,5.03,3.07h23.82c0.23-0.09,0.47-0.18,0.69-0.28
		c3.17-1.39,5.63-3.43,7.39-6.12c0.63-0.96,1.14-1.96,1.55-2.98v9.38h9.9v-5.09h-3.91V24.55h3.91v-5.08h-9.9V28.77z"/>
        <path className="SN_svg" d="M208.8,25.58c-1.34,0-2.48,0.24-3.41,0.73c-0.93,0.49-1.86,1.3-2.77,2.44v-2.78h-6.1v17.09h6.55v-8.21
		c0-1.56,0.25-2.63,0.74-3.23c0.49-0.6,1.14-0.9,1.93-0.9c0.72,0,1.28,0.22,1.67,0.67c0.4,0.44,0.6,1.2,0.6,2.28v9.4h6.58V32.2
		c0-2.25-0.51-3.92-1.54-5C212.03,26.12,210.61,25.58,208.8,25.58"/>
        <polygon className="SN_svg" points="217.24,19.47 217.24,24.55 221.15,24.55 221.15,44.52 217.24,44.52 217.24,49.61 227.14,49.61 
		227.14,19.47 	"/>
      </g>
      <rect x="126.51" y="14.73" className="SN_svg" width="1.07" height="34.88" />
      <g>
        <path className="SN_svg" d="M5.24,19.52c2.45,0,5.04,1.08,5.04,4.01c0,2.83-2.61,4.02-5.06,4.02c-2.48,0-5.11-1.22-5.11-4.07v-5.15
		c0-2.58,2.48-3.78,4.97-3.78c2.61,0,4.88,1.06,4.97,3.42H8.48c-0.05-1.39-1.64-2.09-3.38-2.09s-3.44,0.88-3.44,2.47v2.7
		C2.49,19.92,3.94,19.52,5.24,19.52z M8.72,23.54c0-1.87-1.75-2.61-3.46-2.61s-3.56,0.83-3.56,2.61c0,1.87,1.85,2.58,3.53,2.58
		C6.9,26.09,8.72,25.43,8.72,23.54z"/>
        <path className="SN_svg" d="M12.82,18.01c0.02-2.67,2.65-3.46,4.86-3.46c2.72,0,4.77,1.33,4.79,3.57c0,1.3-0.94,2.41-2.25,2.72
		c1.37,0.34,2.5,1.49,2.5,2.9c0,2.48-2.23,3.76-5.06,3.76c-2.27,0-5.02-0.79-5.02-3.58h1.57c0.02,1.89,2.14,2.14,3.49,2.14
		c2,0,3.46-0.81,3.46-2.27c0-1.06-0.95-2.25-3.38-2.25h-2.03v-1.31h1.93c1.94,0,3.24-0.9,3.26-2.09c0.02-1.04-0.94-2.14-3.22-2.14
		c-1.35,0-3.29,0.34-3.29,2.03h-1.55L12.82,18.01z"/>
        <path className="SN_svg" d="M24.71,16.42c0-1.17,0.83-2.04,2.13-2.04c1.3,0,2.13,0.87,2.13,2.04c0,1.17-0.81,2.02-2.13,2.02
		C25.52,18.44,24.71,17.59,24.71,16.42z M27.82,16.42c0-0.49-0.3-1.02-0.98-1.02c-0.68,0-0.98,0.54-0.98,1.02
		c0,0.49,0.29,1.02,0.98,1.02C27.53,17.44,27.82,16.93,27.82,16.42z"/>
        <path className="SN_svg" d="M41.47,15.88c-1.6,0-3.31,0.63-3.31,2.16c0,1.37,1.57,1.76,3.42,2.07c2.52,0.4,5.01,0.9,5.01,3.75
		c-0.02,2.83-2.72,3.75-5.13,3.75c-2.23,0-4.36-0.81-5.33-2.92l1.3-0.76c0.81,1.49,2.5,2.21,4.05,2.21c1.53,0,3.56-0.49,3.56-2.34
		c0.02-1.57-1.75-2.02-3.64-2.3c-2.43-0.38-4.79-0.94-4.79-3.49c-0.04-2.63,2.65-3.53,4.83-3.53c1.87,0,3.66,0.38,4.77,2.29
		l-1.19,0.74C44.33,16.41,42.87,15.9,41.47,15.88z"/>
        <path className="SN_svg" d="M55.31,14.75l5.65,12.6h-1.69l-1.24-2.77h-7.2l-1.22,2.77h-1.71l5.65-12.6H55.31z M54.43,16.33l-2.99,6.81
		h5.98L54.43,16.33z"/>
        <path className="SN_svg" d="M64.96,25.9h6.86v1.46h-8.43v-12.6h1.57V25.9z" />
        <path className="SN_svg" d="M86,21.09c-0.02,3.29-2.07,6.52-6.39,6.52s-6.41-3.21-6.41-6.52c0-3.66,2.45-6.55,6.43-6.55
		C83.9,14.55,86.02,17.76,86,21.09z M79.63,15.97c-3.21,0-4.86,2.36-4.86,5.11c0,2.56,1.55,5.04,4.86,5.04s4.81-2.56,4.81-5.04
		C84.45,18.53,82.92,16.01,79.63,15.97z"/>
        <path className="SN_svg" d="M90.28,14.75l7.72,9.65v-9.65h1.55v12.6h-1.08l-7.74-9.62v9.62h-1.55v-12.6H90.28z" />
        <path className="SN_svg" d="M103.53,14.75h8.86v1.44h-7.31v3.94h7v1.46h-7v4.27h7.6v1.49h-9.15V14.75z" />
      </g>
      <g>
        <path className="SN_svg" d="M1.18,36.91l7.72,9.65v-9.65h1.55v12.6H9.37L1.63,39.9v9.62H0.08v-12.6H1.18z" />
        <path className="SN_svg" d="M25.25,36.91l5.65,12.6h-1.69l-1.24-2.77h-7.2l-1.22,2.77h-1.71l5.65-12.6H25.25z M24.37,38.49l-2.99,6.81
		h5.98L24.37,38.49z"/>
        <path className="SN_svg" d="M47.93,36.92v7.56c0,3.49-2.36,5.26-5.11,5.26c-2.68,0-5.17-1.73-5.17-5.26v-7.56h1.55v7.56
		c0,2.5,1.67,3.82,3.62,3.82c1.94,0,3.57-1.37,3.57-3.83v-7.56h1.53L47.93,36.92z"/>
        <path className="SN_svg" d="M55.16,38.31v-1.4h10.15v1.4h-4.3v11.2h-1.55v-11.2H55.16z" />
        <path className="SN_svg" d="M72.69,36.91h1.57v12.6h-1.57V36.91z" />
        <path className="SN_svg" d="M88.81,49.71c-4.45,0-6.55-3.08-6.57-6.43c-0.04-3.33,2.14-6.57,6.57-6.57c1.67,0,3.33,0.63,4.61,1.89
		l-1.08,1.01c-0.97-0.95-2.25-1.4-3.53-1.4c-3.38,0-5.02,2.45-5.01,5.06c0.04,2.56,1.6,4.95,5.01,4.95c1.28,0,2.63-0.5,3.58-1.46
		l1.1,1.08C92.21,49.1,90.53,49.71,88.81,49.71z"/>
        <path className="SN_svg" d="M112.89,43.24c-0.02,3.29-2.07,6.52-6.39,6.52s-6.41-3.21-6.41-6.52c0-3.66,2.45-6.55,6.43-6.55
		C110.79,36.71,112.91,39.91,112.89,43.24z M106.52,38.13c-3.2,0-4.86,2.36-4.86,5.11c0,2.56,1.55,5.04,4.86,5.04
		s4.81-2.56,4.81-5.04C111.34,40.69,109.81,38.17,106.52,38.13z"/>
      </g>
  
    </svg>
  );
}

export default SvgSn;
