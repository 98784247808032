import React from "react";
import classNames from "classnames";

export const Button = ({
  variant,
  children,
  onClick,
  type,
  label,
  labelClass,
  startIcon,
  endIcon,
  css,
  disabled=false,
  tooltipMessage=false,
  ...rest
}) => {
  const buttonClasses = (variant) =>
    classNames({
      "border-transparent text-white bg-app-defaultColor p-2":
        !variant || variant === "primary",
      "border-transparent text-white bg-red-600 focus:border-red-600 p-2":
        variant === "danger-primary",
      "border-app-orange text-app-defaultColor bg-white p-2 ":
        variant === "secondary",
      "border-red-600 text-red-600 bg-white focus:border-red-600 p-2":
        variant === "danger",
      "border-transparent text-white bg-app-disabledgrey p-2 cursor-not-allowed":
        variant === "disabled-btn",
      "inline-flex items-center m-2 border-2 rounded-md shadow-sm text-sm font-medium p-2": true,
    });

  return (
    <>
      <button
        type={type || 'button'}
        className={
          buttonClasses(variant) +
          `border-opacity-75 ${css} hover:border-opacity-60 hover:bg-opacity-90 active:outline-none focus:outline-none transition-all`
        }
        onClick={onClick}
        disabled={disabled}        
      >
        {startIcon && startIcon}
        
        <span
          className={classNames(labelClass, startIcon || endIcon ? "px-2" : "")}
        >
          {label || children}
        </span>
        {endIcon && endIcon}
      </button>    
    </>
  );
};
